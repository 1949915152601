import React from 'react';
import { DesktopRowByStatus } from 'pages/PageAssets/components/ui/DesktopRowByStatus';
import { TMinersGroupByModel } from 'pages/PageAssets/types/miners-group-by-model.type';

interface IMinersRowsByModelProps {
  minersGroupByModel: TMinersGroupByModel;
  btcToUsdRate: number;
  trialMiner: boolean;
}

export const DesktopRowsByModel: React.FC<IMinersRowsByModelProps> = ({
  minersGroupByModel,
  btcToUsdRate,
  trialMiner,
}) => {
  const [modelName] = Object.keys(minersGroupByModel);
  const rowsByStatus = minersGroupByModel[modelName];
  const rowsCount: number = Object.keys(rowsByStatus).length;
  const { active: activeMiners, pending: pendingMiners, disabled: disabledMiners } = rowsByStatus;

  return (
    <>
      {activeMiners && (
        <DesktopRowByStatus
          trialMiner={trialMiner}
          groupData={activeMiners}
          statusRowsCount={rowsCount}
          btcToUsdRate={btcToUsdRate}
          shouldShowName
          isFirstOfModel
          isLastOfModel={!pendingMiners && !disabledMiners}
        />
      )}
      {pendingMiners && (
        <DesktopRowByStatus
          trialMiner={trialMiner}
          groupData={pendingMiners}
          statusRowsCount={rowsCount}
          shouldShowName={!activeMiners}
          btcToUsdRate={btcToUsdRate}
          isFirstOfModel={!activeMiners}
          isLastOfModel={!disabledMiners}
        />
      )}
      {disabledMiners && (
        <DesktopRowByStatus
          trialMiner={trialMiner}
          groupData={disabledMiners}
          statusRowsCount={rowsCount}
          shouldShowName={!activeMiners && !pendingMiners}
          btcToUsdRate={btcToUsdRate}
          isFirstOfModel={!activeMiners && !pendingMiners}
          isLastOfModel
        />
      )}
    </>
  );
};
