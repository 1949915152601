import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { MinersBuyingSection } from 'pages/PagePurchase/components/ui/MinersBuyingSection';
import { MinersHeader } from 'pages/PagePurchase/components/ui/MinersHeader';
import { requestAssetsData } from 'store/assets-reducer/assets.thunk-actions';
import {
  requestDashboardElectricity,
  requestDashboardWalletBalance,
} from 'store/dashboard-reducer/dashboard.thunk-actions';
import { selectActiveMiners } from 'store/purchase-reducer/purchase.selectors';
import { fetchActiveMinersThunkAction } from 'store/purchase-reducer/purchase.thunk-actions';
import { fetchPairRatesThunkAction } from 'store/rates-reducer/rates.thunk-actions';
import { useAppDispatch } from 'store/store';

import { SPage } from 'components/styled/SPage';

export const MinersScreen: React.FC = () => {
  const minersData = useSelector(selectActiveMiners);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    void dispatch(fetchPairRatesThunkAction());
    void dispatch(requestDashboardElectricity());
    void dispatch(requestDashboardWalletBalance());
    void dispatch(fetchActiveMinersThunkAction());
    void dispatch(requestAssetsData());
  }, [dispatch]);

  return (
    <SPage headerComponent={<MinersHeader t={t} />}>
      <MinersBuyingSection minersData={minersData} t={t} />
    </SPage>
  );
};
