import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { ICartItemMapped } from 'store/api/cuverse-api/cart/types/cart-response.interface';
import { IMinerDataMapped } from 'store/api/cuverse-api/purchase/types/purchase-active-miners.interface';
import {
  deleteAllCartItemsThunkAction,
  deleteCartItemThunkAction,
  fetchCartThunkAction,
  updateAllCartThunkAction,
  updateCartThunkAction,
} from 'store/cart-reducer/cart.thunk-actions';
import { clearCartAction, clearCartItemsAction } from 'store/cart-reducer/cart-reducer';
import { selectCart } from 'store/cart-reducer/cart-selectors';
import { selectInvoiceStatus } from 'store/invoice-reducer/invoice.selectors';
import { fetchInvoiceThunkAction } from 'store/invoice-reducer/invoice.thunk-actions';
import { closePopupAction } from 'store/modals-reducer/modals.reducer';
import { requestProfileDataThunkAction } from 'store/profile-reducer/profile.thunk-actions';
import { EPromoCodeStatus } from 'store/promocode-reducer/constants/promocode-statuses';
import { setPromoStatusAction } from 'store/promocode-reducer/promocode.reducer';
import { selectActiveMiners } from 'store/purchase-reducer/purchase.selectors';
import { fetchActiveMinersThunkAction } from 'store/purchase-reducer/purchase.thunk-actions';
import { useAppDispatch } from 'store/store';

import { DEBOUNCE_DELAY } from 'components/logic/Cart/constants/debounce-delay';
import { TTransformedCartItem } from 'components/logic/Cart/types/types';
import { mergeArraysById } from 'utils/common/arraysMergeByIdO1';
import { gtmClickCart, gtmClickCartCheckout } from 'utils/gtmSender/gtmSender';
import { AppRoute } from 'utils/route/app-route';

interface IUseCartLogicReturns {
  cartItems: ICartItemMapped[];
  minersData: IMinerDataMapped[];
  cartListWithMinersData: (Omit<ICartItemMapped, 'productId'> & {
    id: number;
  } & IMinerDataMapped)[];
  cartId: number;
  isTooltipOpen: boolean;
  isEmpty: boolean;
  cartItemsAllQuantity: number;
  isCartPending: boolean;
  handleTooltipMouseOver: () => void;
  handleTooltipMouseLeave: () => void;
  handleTooltipOpen: () => void;
  handleTooltipClose: () => void;
  handleIncrement: (id: number, quantity: number, maxQuantity: number) => void;
  handleDecrement: (id: number) => void;
  handleDeleteAllSameItems: (id: number) => void;
  handleDeleteAllItems: () => void;
  handleCheckoutClick: () => void;
  setIsTooltipOpen: (value: boolean) => void;
  t: (key: string) => string;
}

export const useCartLogic = (): IUseCartLogicReturns => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const cartFetchStatus = useSelector(selectInvoiceStatus);
  const minersData = useSelector(selectActiveMiners);
  const cartData = useSelector(selectCart);
  const isCartPending = cartFetchStatus === 'pending';
  const { cartId, cartItems } = cartData;
  const cartItemsAllQuantity = cartItems.reduce((acc, item) => acc + item.quantity, 0);
  const isEmpty = cartItemsAllQuantity === 0;

  const gtmItems = minersData.map((minerData) => {
    const cartItem = cartItems.find((item) => item.productId === minerData.id);
    return {
      item_name: minerData.model,
      item_id: minerData.id,
      price: minerData.price,
      item_brand: minerData.model.split(' ')[0],
      item_hash_rate: minerData.hashrate,
      item_electricity_fee: minerData.dailyElectricityFee,
      quantity: cartItem ? cartItem.quantity : 0,
    };
  });

  const transformCartListId = cartItems.map((el) => {
    const { productId, ...rest } = el;
    return {
      id: productId,
      ...rest,
    };
  });

  const cartListWithMinersData = mergeArraysById<TTransformedCartItem, IMinerDataMapped>(
    transformCartListId,
    minersData,
  );

  const handleTooltipMouseOver = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    setIsTooltipOpen(true);
  };

  const handleTooltipMouseLeave = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      setIsTooltipOpen(false);
    }, DEBOUNCE_DELAY);
  };

  const handleTooltipOpen = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    setIsTooltipOpen(!isTooltipOpen);
  };

  const handleTooltipClose = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    setIsTooltipOpen(false);
  };

  const handleIncrement = (id: number, quantity: number, maxQuantity: number) => {
    if (isCartPending) return;

    if (quantity >= maxQuantity) return;

    const minerDataById = minersData.find((el) => el.id === id);

    if (minerDataById) {
      const { model, id, price, hashrate, dailyElectricityFee } = minerDataById;
      gtmClickCart(model, id, price, model.split(' ')[0], hashrate, dailyElectricityFee);
    }

    const isTrialMinerInCart = cartItems.some(
      (item) => item.productId === minerDataById?.id && item.name.endsWith('rial'),
    );
    if (isTrialMinerInCart) return;

    void dispatch(updateCartThunkAction({ cartId, id }));
  };

  const handleDecrement = (id: number) => {
    !isCartPending && void dispatch(deleteCartItemThunkAction({ cartId, id }));
  };

  const handleDeleteAllSameItems = (id: number) => {
    if (isCartPending) return;
    const updatedCartItems = cartItems
      .filter((item) => item.productId !== id)
      .map((item) => {
        const { productId, quantity } = item;

        if (productId === id)
          return {
            quantity: 0,
            product_id: item.productId,
          };
        return { product_id: productId, quantity };
      });

    void dispatch(updateAllCartThunkAction({ cartId, body: { cart_items: updatedCartItems } }));
  };

  const handleDeleteAllItems = () => {
    if (!isCartPending) {
      void dispatch(deleteAllCartItemsThunkAction({ cartId }));
    }
  };

  const handleCheckoutClick = async () => {
    if (location.pathname === AppRoute.PurchaseCheckout()) {
      setIsTooltipOpen(false);
      return;
    }

    const profileData = await dispatch(requestProfileDataThunkAction()).unwrap();
    const { firstname, lastname, country, city, zip, address, state } = profileData;

    if (!firstname || !lastname || !country || !city || !zip || !address || !state) {
      navigate(AppRoute.PurchasePersonalInfo(), { replace: true });
      setIsTooltipOpen(false);
      dispatch(closePopupAction());
    } else {
      const itemsInCart = gtmItems.filter((el) => el.quantity);
      gtmClickCartCheckout(itemsInCart);
      await dispatch(fetchInvoiceThunkAction(cartId));
      dispatch(setPromoStatusAction(EPromoCodeStatus.Button));
      navigate(AppRoute.PurchaseCheckout(), { replace: true });
      dispatch(closePopupAction());

      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      setIsTooltipOpen(false);
    }
  };

  return {
    cartId,
    cartItems,
    minersData,
    isEmpty,
    cartItemsAllQuantity,
    isTooltipOpen,
    cartListWithMinersData,
    isCartPending,
    handleTooltipMouseOver,
    handleTooltipMouseLeave,
    handleTooltipOpen,
    handleTooltipClose,
    handleIncrement,
    handleDecrement,
    handleDeleteAllSameItems,
    handleDeleteAllItems,
    handleCheckoutClick,
    setIsTooltipOpen,
    t,
  };
};
