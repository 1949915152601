import styled, { css } from 'styled-components';
import { EColors } from 'styles/style-variables/colors';
import { FontFamily } from 'styles/style-variables/font-family';

import { SText } from 'components/styled/SText';

export const MinerModelRow = styled.tr<{ $isFirstOfModel: boolean; $isLastOfModel: boolean }>`
  & td {
    padding-top: 12px;
    vertical-align: top;

    ${({ $isFirstOfModel }) => {
      if ($isFirstOfModel) {
        return css`
          padding-top: 24px;
          border-top: 1px solid rgb(161 161 161 / 30%);
        `;
      }
    }}

    ${({ $isLastOfModel }) => {
      if ($isLastOfModel) {
        return css`
          padding-bottom: 24px;
        `;
      }
    }}
  }

  &:first-of-type td {
    padding-top: 0;
    border-top: none;
  }
`;

export const MinerModelCellWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const MinerModelCell = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  font-family: ${FontFamily.Montserrat};
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  color: ${EColors.Gray1Text};

  & svg {
    flex-shrink: 0;
  }
`;

export const MinerTrialText = styled(SText)`
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0.3px;
  text-align: left;
  text-transform: uppercase;
  color: ${EColors.Gray2};
  font-variant-numeric: lining-nums tabular-nums;
  text-wrap: balance;
`;

export const DoubleValueCell = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

export const PrimaryValue = styled.span<{ $isTurnedOff: boolean }>`
  display: flex;
  align-items: center;
  gap: 4px;
  font-family: ${FontFamily.Montserrat};
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  color: ${({ $isTurnedOff }) => ($isTurnedOff ? '#A1A1A1' : '#272727')};
  font-variant-numeric: lining-nums tabular-nums;

  & svg {
    flex-shrink: 0;
  }
`;

export const SecondaryValue = styled.span<{ $isTurnedOff: boolean }>`
  font-family: ${FontFamily.Montserrat};
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0.2px;
  color: ${({ $isTurnedOff }) => ($isTurnedOff ? '#A1A1A1' : '#646464')};
  font-variant-numeric: lining-nums tabular-nums;
`;

export const MinersValue = styled(SecondaryValue)`
  margin-left: 20px;
`;
