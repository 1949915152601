import styled, { css } from 'styled-components';
import { BreakPoint } from 'styles/style-variables/breakpoint';
import { EColors } from 'styles/style-variables/colors';
import { FontFamily } from 'styles/style-variables/font-family';

import { SText } from 'components/styled/SText';
import { Button } from 'components/ui/Button';

export const MinerItem = styled.li`
  display: flex;
  flex: 1 1 calc(33.333% - 16px);
  flex-direction: column;
  gap: 16px;
  max-width: calc(33.333% - 16px);
  padding: 24px;
  border-radius: 24px;
  background: #fff;
  box-sizing: border-box;

  @media (max-width: ${BreakPoint.TabletTop}) {
    flex: 1 1 calc(50% - 16px);
    max-width: calc(50% - 8px);
  }

  @media (min-width: ${BreakPoint.TabletLow}) and (max-width: ${BreakPoint.TabletTop}) {
    padding: 16px;
    border-radius: 16px;
  }

  @media (max-width: ${BreakPoint.MobileTop}) {
    flex: 1 1 100%;
    max-width: 100%;
    padding: 16px;
    border-radius: 16px;
  }
`;

export const MinerCardHeader = styled.div`
  display: flex;
  gap: 16px;
  padding: 8px;
`;

export const MinerPicture = styled.picture`
  width: 80px;
  height: 80px;

  & img {
    display: block;
    max-width: 100%;
    height: auto;
    object-fit: cover;
  }
`;

export const MinerHeaderTextContent = styled.dl`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 16px;
`;

export const MinerName = styled(SText)`
  font-size: 18px;
  font-style: normal;
  font-weight: 800;
  line-height: 24px;
  justify-self: start;
`;

export const MinerSpecifications = styled.dd`
  display: flex;
  gap: 24px;
`;

export const SpecColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  font-family: ${FontFamily.Montserrat};
  font-style: normal;
  font-weight: 500;
  font-variant-numeric: lining-nums tabular-nums;
`;

export const MinerCardRevenueContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const SpecRow = styled.div`
  display: flex;
  align-items: end;
  justify-content: space-between;
  font-family: ${FontFamily.Montserrat};
  font-style: normal;
  font-weight: 500;
  font-variant-numeric: lining-nums tabular-nums;
`;

export const SpecTittle = styled.span<{ $textAlign?: string }>`
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  color: ${EColors.Gray2};
  ${({ $textAlign }) =>
    $textAlign &&
    css`
      line-height: 12px;
      text-align: ${$textAlign};
      text-wrap: balance;
    `};
`;

export const SpecRowDescription = styled.div`
  display: flex;
  align-items: baseline;
  gap: 4px;
`;

export const SpecHr = styled.hr`
  width: 100%;
  border: 1px solid ${EColors.Gray5};
  border-radius: 50%;
`;

export const DescriptionText = styled(SText)`
  font-size: 10px;
  font-style: normal;
  line-height: 14px;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  color: ${EColors.Gray2};
`;

export const DescriptionValue = styled(SText)`
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  color: ${EColors.Gray1Text};
  font-variant-numeric: lining-nums tabular-nums;
`;

export const DescriptionRowValue = styled(DescriptionValue)`
  font-size: 18px;
  line-height: 24px;
`;

export const MinerPrice = styled(SText)`
  font-size: 24px;
  font-weight: 800;
  line-height: 32px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  margin-top: 16px;
`;

export const MinerInfoButton = styled(Button)`
  flex: 1;
  padding: 12px 0;
  border-radius: 24px;
`;
