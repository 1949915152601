import React, { FC } from 'react';
import { ReactComponent as ClockIcon } from 'assets/icons/button-icons/clock.svg';
import { ReactComponent as MinusIcon } from 'assets/icons/button-icons/minus-bold.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/button-icons/plus-bold.svg';
import { ReactComponent as CartIcon } from 'assets/icons/button-icons/shopping-cart-white.svg';
import { EColors } from 'styles/style-variables/colors';

import { ICartManageButtonProps } from './types/cart-manage-button-props.interface';

import * as S from './CartManageButton.styled';

export const CartManageButton: React.FC<ICartManageButtonProps> = ({
  quantity,
  handleIncrement,
  handleDecrement,
  handleInputChange,
  handleOnMouseOver,
  handleOnMouseLeave,
  handleOnKeyDown,
  handleOnFocus,
  handleOnBlur,
  isMouseOnButton,
  isInitial,
  inputValue,
  availableMinersNumber,
  inputBackgroundColor,
  unit = 'pcs.',
  isSoldOut,
  disabled,
  t,
}) => {
  if (isInitial && isMouseOnButton && !isSoldOut && !quantity) {
    return (
      <S.Container>
        <ButtonsContainer
          quantity={quantity}
          handleIncrement={handleIncrement}
          handleDecrement={handleDecrement}
          handleInputChange={handleInputChange}
          handleOnMouseOver={handleOnMouseOver}
          handleOnMouseLeave={handleOnMouseLeave}
          handleOnKeyDown={handleOnKeyDown}
          handleOnFocus={handleOnFocus}
          handleOnBlur={handleOnBlur}
          inputBackgroundColor={inputBackgroundColor}
          availableMinersNumber={availableMinersNumber}
          inputValue={inputValue}
        />
      </S.Container>
    );
  }

  return (
    <>
      {quantity > 0 ? (
        <S.Container>
          <ButtonsContainer
            quantity={quantity}
            handleIncrement={handleIncrement}
            handleDecrement={handleDecrement}
            handleInputChange={handleInputChange}
            handleOnMouseOver={handleOnMouseOver}
            handleOnMouseLeave={handleOnMouseLeave}
            handleOnKeyDown={handleOnKeyDown}
            handleOnFocus={handleOnFocus}
            handleOnBlur={handleOnBlur}
            inputBackgroundColor={inputBackgroundColor}
            availableMinersNumber={availableMinersNumber}
            inputValue={inputValue}
          />
        </S.Container>
      ) : isSoldOut ? (
        <S.Container onMouseOver={handleOnMouseOver} onMouseLeave={handleOnMouseLeave}>
          <S.OutOfStockBadge>
            <ClockIcon />
            {t('purchase.outOfStock')}
          </S.OutOfStockBadge>
        </S.Container>
      ) : (
        <S.Container onMouseOver={handleOnMouseOver} onMouseLeave={handleOnMouseLeave}>
          <S.AddToCartButton
            type="button"
            variant="primary"
            text={t('purchase.addToCart')}
            icon={<CartIcon />}
            onClick={handleIncrement}
            data-testid={'AddToCartButton'}
            disabled={disabled}
          />
        </S.Container>
      )}
    </>
  );
};

interface IButtonsContainerProps {
  quantity: number;
  handleIncrement: () => void;
  handleDecrement: () => void;
  handleInputChange: (evt: React.ChangeEvent<HTMLInputElement>) => void;
  handleOnMouseOver?: () => void;
  handleOnMouseLeave?: () => void;
  handleOnKeyDown?: (evt: React.KeyboardEvent<HTMLInputElement>) => void;
  handleOnFocus?: () => void;
  handleOnBlur?: () => void;
  inputBackgroundColor?: EColors;
  availableMinersNumber: number;
  inputValue: number;
}

const ButtonsContainer: FC<IButtonsContainerProps> = ({
  quantity,
  handleIncrement,
  handleDecrement,
  handleInputChange,
  handleOnMouseOver,
  handleOnMouseLeave,
  handleOnKeyDown,
  handleOnFocus,
  handleOnBlur,
  inputBackgroundColor,
  availableMinersNumber,
  inputValue,
}) => (
  <S.ButtonsWrapper onMouseOver={handleOnMouseOver} onMouseLeave={handleOnMouseLeave}>
    <>
      <S.CounterButton
        type="button"
        icon={<MinusIcon />}
        onClick={handleDecrement}
        data-testid={'minusButton'}
      />
      <S.CounterInput
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        onKeyDown={handleOnKeyDown}
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
        $backgroundColor={inputBackgroundColor || EColors.White}
      />
      <S.CounterButton
        type="button"
        icon={<PlusIcon />}
        disabled={availableMinersNumber <= 0}
        onClick={handleIncrement}
        data-testid={'plusButton'}
      />
    </>
  </S.ButtonsWrapper>
);
